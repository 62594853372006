import React from 'react';

const Footer = () => {
	return (
		<div className="mx-auto lg:h-full bg-gray-100 text-gray-500 text-xs text-center tracking-widest font-black relative">
			<footer className="w-full text-center border-t border-grey p-4 pin-b absolute bg-gray-100">
				© 2021 Oscar Sanchez Jr.
			</footer>
		</div>
	);
};

export default Footer;
